import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getMessaging, getToken, isSupported } from 'firebase/messaging'
import firebase from 'firebase/compat/app'

import { DRAWER_TYPES, vapidKey } from '@constants'
import { Loader, Toast, TOAST_TYPE } from '@base'
import { getFirebaseUser, getPlatform } from '@utils'
import { setSideBarTitle, setUser, showToast, setOrganisation } from '@data/state/action'
import Drawer from '@global/Drawer'
import Modal from '@global/Modal'
import SideMenu from '@global/SideMenu'
import Header from '@global/Header'
import GlobalFileViewer from '@global/GlobalFileViewer'
import { paths, OrganisationRouteTitleMap, AdminRouteTitleMap } from '@routes'
import { DarshboardContainer, Container, Body, LoaderWrapper, LoaderBackground } from './styles'
import { getOrganisationByUser, getOrganisationKycData, updateUserFCMToken } from '@data'
import { toggleDrawer } from '@data/state/action/root'
import NotificationPlayer from '@base/composite/NotificationPlayer'
import { useForceUpdate } from '@hooks'
import { isClient } from '@utils/adminFilter'

const LoaderComponent = () => {
	const showLoader = useSelector((state) => state.getIn(['root', 'showLoader']))
	return (
		showLoader && (
			<LoaderWrapper>
				<LoaderBackground />
				<Loader />
			</LoaderWrapper>
		)
	)
}

function DashboardLayout() {
	const navigate = useNavigate()
	const location = useLocation()
	const dispatch = useDispatch()
	const usePlatform = getPlatform()
	const user = useSelector((state) => state.getIn(['user', 'user']))
	const organisation = useSelector((state) => state.getIn(['user', 'organisation']))

	const [forceUpdate, update] = useForceUpdate()

	const [showMenu, setShowMenu] = useState(false)

	useEffect(() => {
		getFirebaseUser(navigate, (u) => {
			dispatch(setUser(u))
		})
	}, [])

	useEffect(() => {
		const app = firebase.app()
		if (isSupported() && user) {
			const messaging = getMessaging(app)

			getToken(messaging, { vapidKey })
				.then((currentToken) => {
					if (currentToken) {
						// Send the token to your server and update the UI if necessary
						// ...
						return updateUserFCMToken(currentToken, user.user_id)
					} else {
						// Show permission request UI
						console.log('No registration token available. Request permission to generate one.')
					}
				})
				.catch((err) => {
					console.log('An error occurred while retrieving token. ', err, typeof err)
					if (err.code === 'messaging/permission-blocked') {
						dispatch(
							showToast(true, `Notifications are blocked. Please check your browser setting and grant permission`, {
								type: TOAST_TYPE.INFO,
							})
						)
					}
				})
		}
	}, [user])

	useEffect(() => {
		if (user?.user_id && isClient(user?.role)) {
			getOrganisationByUser(user.user_id)
				.then((res) => {
					dispatch(setOrganisation(res))
				})
				.catch((err) => console.log(err))
		}
	}, [forceUpdate, user])

	useEffect(() => {
		if (isClient(user?.role) && organisation?.kyc_requested) {
			getOrganisationKycData(organisation?.organisation_id)
				.then((res) => {
					dispatch(toggleDrawer(true, DRAWER_TYPES.COMPLETE_YOUR_KYC, { kycData: res, adminView: !isClient(user?.role), update }))
				})
				.catch((err) => console.log(err))
		}
	}, [organisation])

	useEffect(() => {
		if (!user) return

		let mapObject = {}

		if (isClient(user.role)) {
			mapObject = OrganisationRouteTitleMap
		} else {
			mapObject = AdminRouteTitleMap
		}

		const _p = location.pathname.toLowerCase()

		if (_p === paths.admin || _p === paths.logistics) return

		const [, title] = Object.entries(mapObject).find(([key, val]) => _p.includes(key)) ?? ['', '']
		dispatch(setSideBarTitle(title))
	}, [location.pathname, user, organisation])

	const menuClickHandler = (flag) => {
		setShowMenu(flag)
	}

	if (!user) return null

	if (isClient(user.role)) {
		if (location.pathname.includes(paths.admin)) {
			navigate(paths.home)
		}
	} else {
		if (!location.pathname.includes(paths.admin)) {
			navigate(paths.admin)
		}
	}

	const checkMak = usePlatform === 'Mac' || usePlatform === 'iOS'

	return (
		<DarshboardContainer>
			<SideMenu user={user} showMenu={showMenu} menuCloseHandler={menuClickHandler} />
			<Container>
				<Header menuButtonHandler={menuClickHandler} />
				<Body IsMac={checkMak}>
					<Outlet />
				</Body>
				<LoaderComponent />
			</Container>
			<Toast />
			<Drawer />
			<Modal />
			<GlobalFileViewer />
			<NotificationPlayer />
		</DarshboardContainer>
	)
}

export default DashboardLayout
