import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Menu } from '@material-ui/icons'
import { H4, H6, Link, BackButton } from '@base'
import { Devices, Spacings, Text } from '@styles'
import News from '@pages/Dashboard/News'
import NotificationBar from '@global/NotificationBar'
import PriceWidget from './PriceWidget'

const HeaderWrapper = styled.section`
	display: flex;
	background: ${({ theme }) => theme.palette.background.white};
	padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_3B};
	align-items: center;
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	margin-bottom: ${Spacings.SPACING_4B};
	position: sticky;
	top: 0;
	z-index: 499;
	min-height: 3.875rem;

	flex-wrap: wrap;
`

const NewsWrapper = styled.div`
	width: 100%;
	margin-top: ${Spacings.SPACING_2B};
	padding: ${Spacings.SPACING_1B} 0;
	border-top: 1px solid ${({ theme }) => theme.palette.background.border};
`

const ButtonWrapper = styled.div`
	align-items: center;
	justify-content: center;
	width: 1.5rem;
	height: 1.5rem;
	position: relative;
	display: flex;
	flex-direction: column;
	cursor: pointer;
	user-select: none;
	margin-left: ${Spacings.SPACING_1B};
	margin-right: ${Spacings.SPACING_4B};

	@media ${Devices.laptop} {
		display: none;
	}

	& > div {
		position: absolute;
		left: 0.125rem;
		top: 0.3125rem;
		width: calc(100% - 0.25rem);
		height: 0.125rem;
		border-radius: ${Spacings.SPACING_2B};
		background: ${({ theme }) => theme.palette.background.borderColor};
		transition: 0.1s background ease-in-out;

		&:nth-child(2) {
			top: calc(50% - 0.0625rem);
		}
		&:last-of-type {
			top: calc(100% - 0.4375rem);
		}
	}

	&:hover {
		& > div {
			background: ${({ theme }) => theme.palette.background.dark};
		}
	}
`

const PageInfo = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;
`

const InternalInfoWrapper = styled.div`
	flex-grow: 1;
`

const BreadCrumbsWrapper = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
`

const PageTitle = styled(H4)`
	color: ${({ theme }) => theme.palette.text.tableHeader};
	line-height: ${Text.LINE_HEIGHT_MAX};
`

const Crumb = styled(H6)`
	color: ${({ theme }) => theme.palette.text.blue};
	line-height: ${Text.LINE_HEIGHT_MAX};
`
const ChevronWrapper = styled.div`
	height: 0.5rem;
	width: 0.5rem;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 0.5rem;
`

const ExtraDivWrapper = styled.div`
	flex: 1;
`

const BreadCrumbs = ({ crumbs }) => {
	if (!crumbs?.length) return null

	return (
		<BreadCrumbsWrapper>
			{crumbs.map((crumb, index) => {
				let elem
				if (crumb.onClick) {
					elem = <Crumb onClick={crumb.onClick}>{crumb?.label}</Crumb>
				} else {
					elem = (
						<Link href={crumb?.href}>
							<Crumb>{crumb?.label}</Crumb>
						</Link>
					)
				}
				return (
					<Fragment key={`breadcrumb-${index}`}>
						{elem}
						<ChevronWrapper>/</ChevronWrapper>
					</Fragment>
				)
			})}
		</BreadCrumbsWrapper>
	)
}

const Header = ({ menuButtonHandler }) => {
	const selectedTitle = useSelector((state) => state.getIn(['root', 'sidebarTitle']))
	const headerMeta = useSelector((state) => state.getIn(['root', 'headerMeta']))
	const showNews = !!headerMeta?.showNewsInHeader
	const user = useSelector((state) => state.getIn(['user', 'user']))
	const organisation = useSelector((state)=>state.getIn(['user','organisation']))

	const renderMenuButton = () => (
		<ButtonWrapper onClick={menuButtonHandler}>
			<Menu />
		</ButtonWrapper>
	)

	return (
		<HeaderWrapper>
			{renderMenuButton()}
			<PageInfo>
				<BackButton desktop backHandler={headerMeta?.backHandler} />
				<InternalInfoWrapper>
					<BreadCrumbs crumbs={headerMeta?.breadCrumbs ?? []} />
					<PageTitle>{headerMeta?.title ?? selectedTitle}</PageTitle>
				</InternalInfoWrapper>
				<ExtraDivWrapper />
				<PriceWidget user={user} organisation={organisation} />
				<NotificationBar />
				<BackButton margin='0' mobile backHandler={headerMeta?.backHandler} />
			</PageInfo>
			{showNews && (
				<NewsWrapper>
					<News inHeader />
				</NewsWrapper>
			)}
		</HeaderWrapper>
	)
}

export default Header
