import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FileViewer } from '@base'
import { getServerUrl } from '@utils'
import { toggleFileViewer } from '@data/state/action/root'
import { useMemo } from 'react'

const GlobalFileViewer = () => {
	const serverUrl = getServerUrl()
	const dispatch = useDispatch()
	const showFileViewer = useSelector((state) => state.getIn(['root', 'showFileViewer']))
	const { files, local, ...rest } = useSelector((state) => state.getIn(['root', 'fileViewerData']))

	const filesProp = useMemo(() => {
		if (local) {
			return files
		}
		if (files) {
			let _files = files
			if (!Array.isArray(_files)) {
				_files = [_files]
			}
			return _files.map((f) => serverUrl + f)
		}
		return []
	}, [files, local])

	const close = useCallback(() => {
		dispatch(toggleFileViewer(false))
	}, [])

	return <FileViewer show={showFileViewer} files={filesProp} onClose={close} {...rest} />
}

export default GlobalFileViewer
