import styled, { css } from 'styled-components'
import { Devices, Spacings } from '@styles'

export const DarshboardContainer = styled.section`
	display: flex;
	height: 100%;
`
export const Container = styled.section`
	display: flex;
	flex: 1;
	flex-direction: column;
	height: 100%;

	max-height: 100%;

	overflow-y: hidden;
	overflow-x: hidden;
	position: relative;
`
export const Body = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	max-height: 100%;
	height: 100%;

	overflow-y: scroll;
	overflow-x: hidden;
	/* scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		width: 7px;
	}
	::-webkit-scrollbar-thumb {
		background: rgba(0, 0, 0, 0.2);
		transform: translateY(0px);
		border-radius: 10px;
	}
	/* scrollbar for IE, Edge and Firefox */
	scrollbar-width: thin;

	${({ IsMac }) => {
		if (IsMac) {
			return css`
				/* Hide scrollbar for Chrome, Safari and Opera */
				&::-webkit-scrollbar {
					display: none;
				}
				/* Hide scrollbar for IE, Edge and Firefox */
				& {
					-ms-overflow-style: none; /* IE and Edge */
					scrollbar-width: none; /* Firefox */
				}
			`
		}
	}}

	@media ${Devices.tablet} {
		padding: 0 ${Spacings.SPACING_4B};
	}
`

const LoaderBaseWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`

export const LoaderWrapper = styled(LoaderBaseWrapper)`
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;
	z-index: 1002;
`

export const LoaderBackground = styled(LoaderBaseWrapper)`
	background: ${({ theme }) => theme.palette.background.white}8f;
`
