import moment from 'moment'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { Message } from '@material-ui/icons'

import { paths } from '@routes'
import { Spacings, Text } from '@styles'
import { toggleDrawer } from '@data/state/action/root'
import { DrawerBodyWrapper, EmptyState, H4, H6 } from '@base'
import { isClient } from '@utils/adminFilter'

const NotifCardWrapper = styled.div`
	cursor: pointer;
	background: ${({ theme }) => theme.palette.background.white};
	padding: ${Spacings.SPACING_4B};
	transition: background 0.15s ease-in;
	margin-bottom: ${Spacings.SPACING_2B};
	border-bottom: 1px solid ${({ theme }) => theme.palette.background.searchgrey};
	&:hover {
		border-radius: ${Spacings.SPACING_2B};
		background: ${({ theme }) => theme.palette.background.blueLightest};
	}
`

const TitleWrapper = styled(H4)`
	font-size: ${Text.MEDIUM};
	color: ${({ theme }) => theme.palette.text.main};
`

const TimeWrapper = styled(H6)`
	color: ${({ theme }) => theme.palette.text.blue};
	font-weight: ${Text.BOLD_600};
	margin-top: ${Spacings.SPACING_1B};
`

const EmptyStateWrapper = styled.div`
	height: 30rem;
`
const NotificationsWrapper = styled.div``

const NotificationsList = ({ notifications, user }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const navigateOptions = {
		state: {
			showNegotiation: true,
		},
	}

	const onCardClick = (notification) => {
		if (isClient(user.role)) {
			if (notification.source === 'ENQUIRY' || notification.title.startsWith('E')) {
				navigate(paths.submit_enquiry_quote(notification.title), navigateOptions)
			} else if (notification.source === 'AUCTION' || notification.title.startsWith('A')) {
				navigate(paths.edit_auction(notification.title), navigateOptions)
			}
		} else {
			if (notification.source === 'ENQUIRY' || notification.title.startsWith('E')) {
				navigate(paths.admin_manage_enquiry_manage(notification.title), navigateOptions)
			} else if (notification.source === 'AUCTION' || notification.title.startsWith('A')) {
				navigate(paths.admin_manage_auction_manage(notification.title), navigateOptions)
			}
		}

		dispatch(toggleDrawer(false))
	}

	const renderNotifications = () => {
		if (!Array.isArray(notifications) || notifications.length === 0) {
			return (
				<EmptyStateWrapper>
					<EmptyState icon={<Message />} noAction text='No new notifications' />
				</EmptyStateWrapper>
			)
		}
		return (
			<NotificationsWrapper>
				{notifications.map((_n, index) => (
					<NotifCardWrapper onClick={() => onCardClick(_n)} key={index}>
						<TitleWrapper>{_n.body}</TitleWrapper>
						<TimeWrapper>{moment(_n?.updated_at).fromNow()}</TimeWrapper>
					</NotifCardWrapper>
				))}
			</NotificationsWrapper>
		)
	}

	return <DrawerBodyWrapper>{renderNotifications()}</DrawerBodyWrapper>
}

export default NotificationsList
