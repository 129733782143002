import React, { useEffect, useState } from 'react'
import { getMessaging, onMessage } from 'firebase/messaging'
import styled, { keyframes } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Notifications as NotificationsIcon, NotificationsOff as NotificationsOffIcon } from '@material-ui/icons'
import { Spacings } from '@styles'
import { getNotification } from '@data'
import { DRAWER_TYPES, roles } from '@constants'
import { playNotification, toggleDrawer } from '@data/state/action/root'
import { isClient } from '@utils/adminFilter'

const NotificationBarWrapper = styled.div`
	color: ${({ theme }) => theme.palette.text.blue};
	position: relative;
	margin-right: ${Spacings.SPACING_2B};

	display: flex;
	align-items: center;
	justify-content: center;
`
const PulseAnimation = keyframes`
    0% { transform: scale(0.8) }
    12% { transform: scale(0.9) }
    24% { transform: scale(1) }
    36% { transform: scale(1.1) }
    48% { transform: scale(1.2) }
    60% { transform: scale(1.1) }
    72% { transform: scale(1) }
    84% { transform: scale(0.9) }
    100% { transform: scale(0.8) }
`

const NotificationCount = styled.div`
	position: absolute;
	width: ${Spacings.SPACING_2B};
	height: ${Spacings.SPACING_2B};
	top: 0;
	right: 0;
	background: ${({ theme }) => theme.palette.background.red};
	border-radius: 50%;
	animation-name: ${PulseAnimation};
	animation-duration: 1s;
	animation-iteration-count: infinite;
`

const NotificationBar = () => {
	const dispatch = useDispatch()
	const user = useSelector((state) => state.getIn(['user', 'user']))
	const [newNotif, setNewNotif] = useState(false)
	const [notifications, setNotification] = useState([])

	useEffect(() => {
		const userId = !isClient(user.role) ? null : user.user_id
		const msgBody = (res) => {
			const _lastUser = res?.messages?.slice(-1)?.some((item) => item?.created_by !== res?.user_details?.user_id)
			if (userId) {
				return _lastUser ? (
					<>
						{'Message received ' +
							(isClient(user.role) ? '': `from ${res.user_details.full_name || res.user_details.mobile_number} `) +
							`for ${res.source.toLowerCase()}: ${res.enquiry_id || res.auction_id}`}
					</>
				) : (
					<>{`Message sent for enquiry: (${res?.enquiry_id})`}</>
				)
			} else {
				return _lastUser ? (
					<>{`Message sent to (${res?.user_details?.full_name || res?.user_details?.mobile_number}) for enquiry: (${res?.enquiry_id})`}</>
				) : (
					<>
						{'Message received ' +
							(isClient(user.role) ? '' : `from ${res.user_details.full_name || res.user_details.mobile_number} `) +
							`for ${res.source.toLowerCase()}: ${res.enquiry_id || res.auction_id}`}
					</>
				)
			}
		}
		getNotification(userId)
			.then((response) => {
				const newResponse = response.map((res) => {
					return {
						updated_at: res.updated_at,
						title: res.enquiry_id,
						source: res.source,
						body: msgBody(res),
					}
				})
				setNotification(newResponse)
			})
			.catch((err) => {})
	}, [])

	useEffect(() => {
		const messaging = getMessaging()
		onMessage(messaging, (payload) => {
			payload.notification.updated_at = Date.now()
			payload.notification.body = `You have received a new message for enquiry: ${payload.notification.title}`
			setNewNotif(true)
			setNotification([payload.notification, ...notifications])
			dispatch(playNotification(true))
		})
	}, [notifications])

	const onNotificationIconClick = () => {
		setNewNotif(false)
		dispatch(toggleDrawer(true, DRAWER_TYPES.NOTIFICATIONS, { notifications, user, label: 'Notifications' }))
	}

	return (
		<NotificationBarWrapper>
			<NotificationsIcon fontSize='small' onClick={onNotificationIconClick} style={{ cursor: 'pointer' }} />
			{newNotif && <NotificationCount />}
		</NotificationBarWrapper>
	)
}

export default NotificationBar
