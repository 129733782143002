import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { ArrowBack } from '@material-ui/icons'

import { DRAWER_TYPES, strings } from '@constants'
import { toggleDrawer } from '@data/state/action/root'

import CreateItem from '@pages/Enquiry/CreateItem'
import CreateAddress from '@pages/Profile/CreateAddress'
import ViewEnquiry from '@pages/Enquiry/ViewEnquiry'
import ViewOpenEnquiry from '@pages/Fulfilment/ViewOpenEnquiry'
import AddInventory from '@pages/Inventory/AddInventory'
import ViewInvoice from '@pages/Orders/ViewInvoice'
import EditDetails from '@pages/Profile/EditDetails'
import AssignToEmployee from '@pages/Admin/Enquiry/ManageEnquiries/AssignEnquiry/AssignToEmployee'
import { SearchPanel } from '@base'
import CreateCategory from '@pages/Admin/Catalog/CreateCategory'
import CreateProduct from '@pages/Admin/Catalog/CreateProduct'
import AdminEditInvoice from '@pages/Admin/Orders/ManageOrder/EditInvoice'
import AdminAddInventory from '@pages/Admin/Inventory/AddInventory'
import News from '@pages/Dashboard/News'
import OrderSummary from '@pages/Orders/OrderSummary'
import AdminOrderSummary from '@pages/Admin/Orders/ManageOrder/OrderSummary'
import AdminAddInvoice from '@pages/Admin/Invoice/AddInvoice'
import AdminAuctionOrderSummary from '@pages/Admin/AuctionOrders/ManageOrder/OrderSummary'
import AssignAuctionToEmployee from '@pages/Admin/Auction/ManageAuctions/AssignAuction/AssignAuctionToEmployee'
import SelectAddress from '@pages/Profile/SelectAddress'
import QuoteSubmission from '@pages/AuctionBid/QuoteSubmission'
import AddTransaction from '@pages/Admin/PaymentSummary/AddTransaction'
import CreateLogisticsItem from '@pages/Admin/Logistics/CreateLogistics/CreateLogisticsItem'
import AddLogisticsQuotes from '@pages/Admin/Logistics/CreateLogistics/AddLogisticsQuotes'
import LogisticsPartnerQuotes from '@pages/Logistics/CreateLogistics/LogisticsPartnerQuotes'
import AdminEditLogisticsInvoice from '@pages/Admin/Logistics/ManageLogistics/AdminEditLogisticsInvoice'
import ShareEnquiry from '@pages/Admin/Enquiry/ManageEnquiries/AllEnquiries/ShareEnquiry'
import CloudFactoryAddInventoryItems from '@pages/Admin/CloudFactory/Orders/CreateOrder/Steps/InputItemsStep/AddInventoryItems'
import CloudFactoryAddEnquiryItems from '@pages/Admin/CloudFactory/Orders/CreateOrder/Steps/OuputItemsStep/AddEnquiryItems'
import AdminEditFactoryInvoice from '@pages/Admin/CloudFactory/Orders/ManageOrder/Invoices/EditInvoice'
import ManageItemJourney from '@pages/Admin/CloudFactory/Orders/ManageOrder/OrderJourney/ManageItemJourney'

import { DrawerOverlay, DrawerWrapper, DrawerHeader, IconWrapper, DrawerBody, ImageWapper, Image } from './styles'
import EditFactoryInvoice from '@pages/CloudFactory/ManageOrders/ManageOrder/Invoices/EditInvoice'
import ManageOrderWasteItem from '@pages/Admin/CloudFactory/Orders/ManageOrder/OrderWaste/ManageWasteItem'
import ClosingReasonForm from '@pages/Admin/Enquiry/ManageEnquiries/ManageEnquiry/ClosingReasonForm'
import AddLogisticsPrice from '@pages/Admin/Logistics/LogisticsPrice/AddLogisticsPrice'
import AdminFreightCalculator from '@pages/Admin/Enquiry/ManageEnquiries/ManageEnquiry/FreightCalculator'
import AdminGenerateEstimate from '@pages/Admin/Enquiry/ManageEnquiries/ManageEnquiry/GenerateEstimate'
import FreightCalculator from '@pages/Fulfilment/FreightCalculator'
import AdminInvoices from '@pages/Admin/Invoice/EditInvoice'
import AdminNewReport from '@pages/Admin/Underwriting/NewReport'
import UnderwritingStatus from '@pages/Admin/Underwriting/UnderwritingStatus'
import AdminSelesTeamFeedback from '@pages/Admin/Underwriting/ViewReport/SalesTeam/Sales/index'
import AdminFinanceFeedback from '@pages/Admin/Underwriting/ViewReport/SalesTeam/Finance/index'
import AdminCrossDirectorList from '@pages/Admin/Underwriting/ViewReport/FinalReport/CrossDirectorList'
import CompleteKYCDrawer from '@pages/Profile/CompleteProfile'
import AddCommodityPrice from '@pages/Admin/CommodityPrices/AddCommodityPrice'
import RequestCredit from '@pages/Profile/RequestCredit/RequestCredit'
import PastReport from '@pages/Admin/Underwriting/PastReport'
import NotificationsList from '@global/NotificationBar/NotificationsList'
import AdminAddBanner from '@pages/Admin/Content/Banner/AddBanner'
import CreateBrand from '@pages/Admin/Catalog/BrandList/CreateBrand'
import CreateCommodity from '@pages/Admin/Catalog/Commodity/CreateCommodity'
import AddOrgAddress from '@pages/Admin/OrgsAndUsers/Organisation/DrawerComponents/AddOrgAddress'
import AddGST from '@pages/Admin/OrgsAndUsers/Organisation/DrawerComponents/AddGST'
import AddOrgRole from '@pages/Admin/OrgsAndUsers/Organisation/DrawerComponents/AddOrgRole'
import EditUserDetails from '@pages/Admin/OrgsAndUsers/Organisation/DrawerComponents/EditUserDetails'
import AddNewUser from '@pages/Admin/OrgsAndUsers/Organisation/DrawerComponents/AddNewUser'
import OrgRemark from '@pages/Admin/OrgsAndUsers/Organisation/DrawerComponents/OrgRemark'
import CreateNews from '@pages/Admin/Content/News/CreateNews'
import AddCommodity from '@pages/Admin/OrgsAndUsers/Organisation/DrawerComponents/AddCommodity'
import CommodityCategoryList from '@pages/Admin/OrgsAndUsers/Organisation/DrawerComponents/CommodityCategoryList'
import AddOrderItem from '@pages/Admin/Orders/ManageOrder/AddOrderItem'
import { Dashboard } from '@routes/Routes/OrganisationRoutes'
import AddNewOrganisation from '@pages/Admin/OrgsAndUsers/Organisation/DrawerComponents/AddNewOrganisation'

import OrderJourney from '@pages/Admin/Orders/OrderJourney'
import AdminChoosePhoto from '@pages/Admin/Enquiry/CreateEnquiry/Steps/ChoosePhoto'
import { downloadUrl } from '@data'
import EnquiryJourney from '@pages/Admin/Enquiry/CreateEnquiry/Steps/EnquiryJourney'
import EditOrgPanDetails from '@pages/Admin/OrgsAndUsers/Organisation/DrawerComponents/EditOrgPAnDetails'
import EditOrgName from '@pages/Admin/OrgsAndUsers/Organisation/DrawerComponents/EditOrgName'
import MergeOrganisation from '@pages/Admin/OrgsAndUsers/Organisation/DrawerComponents/MergeOrganisation'
import CreateCollection from '@pages/Admin/PaymentSummary/Collections/CreateCollection'
import CollectionJourney from '@pages/Admin/PaymentSummary/Collections/CollectionJourney'
import { withSuspense } from '@routes/Routes/routeUtils'
import SelectScrapSupplier from '@pages/Admin/Orders/ManageOrder/SelectScrapSupplier'
import EditTasks from '@pages/Admin/OrgsAndUsers/Organisation/DrawerComponents/AddTasks'
import AddLead from '@pages/Admin/OrgsAndUsers/Organisation/DrawerComponents/AddLead'
import LeadJourney from '@pages/Admin/OrgsAndUsers/Organisation/DrawerComponents/LeadJourney'
import MonthlyTarget from '@pages/Admin/OrgsAndUsers/Organisation/DrawerComponents/MonthlyTarget'
import DeleteAdmin from '@pages/Admin/OrgsAndUsers/Organisation/DrawerComponents/DeleteAdmin'
import AddBank from '@pages/Admin/OrgsAndUsers/Organisation/DrawerComponents/AddBank'
import PdcForm from '@pages/Admin/OrgsAndUsers/Organisation/Financial/Pdc/PdcForm'
import BusinessDetailsForm from '@pages/Admin/OrgsAndUsers/Organisation/Financial/BusinessDetails/BusinessDetailsForm'
import CompleteLead from '@pages/Admin/OrgsAndUsers/Lead/CompleteLead'
import EditLeadManager from '@pages/Admin/OrgsAndUsers/Organisation/DrawerComponents/EditLeadManager'
import EditInsurerCredit from '@pages/Admin/OrgsAndUsers/Organisation/Financial/AdhocJourney/EditInsurerCredit'
import Remark from '@pages/Admin/OrgsAndUsers/Organisation/Financial/Remarks/Remark'
import BankForm from '@pages/Admin/OrgsAndUsers/Organisation/Financial/CustomerDetails/BankForm'
import SecurityForm from '@pages/Admin/OrgsAndUsers/Organisation/Financial/CustomerDetails/SecurityForm'
import PaymentModeForm from '@pages/Admin/OrgsAndUsers/Organisation/Financial/CustomerDetails/PaymentModeForm'
import TradingExpenseForm from '@pages/Admin/Tools/DataCorrection/Ledger/TradingExpenseForm'
import AddFaq from '@pages/Admin/Content/Blog/AddFaq'
import AddCreditLimit from '@pages/Admin/OrgsAndUsers/Organisation/Financial/CreditLimit/AddCreditLimit'
import OrgServices from '@pages/Admin/OrgsAndUsers/Organisation/DrawerComponents/OrgServices'
import AddFinancialDoc from '@pages/Admin/OrgsAndUsers/Organisation/Financial/Documents/AddFinancialDoc'
import EditIncorporationDate from '@pages/Admin/OrgsAndUsers/Organisation/DrawerComponents/EditIncorporationDate'

const Drawer = () => {
	const dispatch = useDispatch()
	const showDrawer = useSelector((state) => state.getIn(['root', 'showDrawer']))
	const drawerType = useSelector((state) => state.getIn(['root', 'drawerType']))
	const drawerTypeMeta = useSelector((state) => state.getIn(['root', 'drawerTypeMeta']))

	const location = useLocation()

	useEffect(() => {
		if (showDrawer) {
			closeDrawerHandler()
		}
	}, [location])

	const renderComponentFromDrawerType = () => {
		switch (drawerType) {
			case DRAWER_TYPES.ADD_NEW_ENQUIRY_ITEM:
				return <CreateItem label={strings('add_item_details')} {...drawerTypeMeta} />
			case DRAWER_TYPES.EDIT_ENQUIRY_ITEM:
				return <CreateItem label={strings('edit_item_details')} {...drawerTypeMeta} />
			case DRAWER_TYPES.VIEW_ENQUIRY:
				return <ViewEnquiry {...drawerTypeMeta?.data} />
			case DRAWER_TYPES.ADD_NEW_ADDRESS:
				return <CreateAddress {...drawerTypeMeta} />
			case DRAWER_TYPES.SELECT_ADDRESS_FROM_ADDRESSBOOK:
				return <SelectAddress {...drawerTypeMeta} />
			case DRAWER_TYPES.VIEW_OPEN_ENQUIRY:
				return <ViewOpenEnquiry enquiry={drawerTypeMeta?.data} />
			case DRAWER_TYPES.ADD_INVENTORY:
				return <AddInventory {...drawerTypeMeta} />
			case DRAWER_TYPES.VIEW_ORDER_INVOICE:
				return <ViewInvoice {...drawerTypeMeta} />
			case DRAWER_TYPES.EDIT_PERSONAL_DETAILS:
				return <EditDetails type={drawerType} label={strings('update_account_details')} {...drawerTypeMeta} />
			case DRAWER_TYPES.EDIT_ORG_DETAILS:
				return <EditDetails type={drawerType} label={strings('update_organisation_details')} {...drawerTypeMeta} />
			case DRAWER_TYPES.ADMIN_ASSIGN_ENQUIRY:
				return <AssignToEmployee {...drawerTypeMeta} />
			case DRAWER_TYPES.OPEN_SEARCH_PANEL:
				return <SearchPanel {...drawerTypeMeta} label={strings('search')} />
			case DRAWER_TYPES.ADD_CATEGORY:
				return <CreateCategory label={strings('create', 'category')} {...drawerTypeMeta} />
			case DRAWER_TYPES.EDIT_CATEGORY:
				return <CreateCategory label={strings('edit', 'category')} {...drawerTypeMeta} />
			case DRAWER_TYPES.ADD_PRODUCT:
				return <CreateProduct label={strings('create', 'product')} {...drawerTypeMeta} />
			case DRAWER_TYPES.EDIT_PRODUCT:
				return <CreateProduct label={strings('edit', 'product')} {...drawerTypeMeta} />
			case DRAWER_TYPES.ADD_BRAND:
				return <CreateBrand label={strings('create', 'brand')} {...drawerTypeMeta} />
			case DRAWER_TYPES.ADD_COMMODITY:
				return <CreateCommodity label={strings('create', 'commodity')} {...drawerTypeMeta} />
			case DRAWER_TYPES.EDIT_COMMODITY:
				return <CreateCommodity label={strings('edit', 'commodity')} {...drawerTypeMeta} />
			case DRAWER_TYPES.ADMIN_EDIT_ORDER_INVOICE:
				return <AdminEditInvoice {...drawerTypeMeta} />
			case DRAWER_TYPES.ADMIN_ADD_INVENTORY:
				return <AdminAddInventory {...drawerTypeMeta} />
			case DRAWER_TYPES.ADMIN_ADD_BANNER:
				return <AdminAddBanner {...drawerTypeMeta} />
			case DRAWER_TYPES.ADMIN_EDIT_BANNER:
				return <AdminAddBanner {...drawerTypeMeta} />
			case DRAWER_TYPES.ADMIN_ADD_NEWS:
				return <CreateNews {...drawerTypeMeta} />
			case DRAWER_TYPES.ADMIN_EDIT_NEWS:
				return <CreateNews {...drawerTypeMeta} />
			case DRAWER_TYPES.SHOW_NEWS:
				return <News {...drawerTypeMeta} label={strings('latest', 'news')} />
			case DRAWER_TYPES.VIEW_ORDER_SUMMARY:
				return <OrderSummary {...drawerTypeMeta} label={strings('view_order_summary')} />
			case DRAWER_TYPES.ADMIN_VIEW_ORDER_SUMMARY:
				return <AdminOrderSummary {...drawerTypeMeta} label={strings('view_order_summary')} />
			case DRAWER_TYPES.ADMIN_FETCH_BILL:
				return <AdminAddInvoice {...drawerTypeMeta} />
			case DRAWER_TYPES.ADMIN_ASSIGN_AUCTION:
				return <AssignAuctionToEmployee {...drawerTypeMeta} label={strings('assign', 'auction')} />
			case DRAWER_TYPES.ADMIN_VIEW_AUCTION_ORDER_SUMMARY:
				return <AdminAuctionOrderSummary {...drawerTypeMeta} label={strings('view_order_summary')} />
			case DRAWER_TYPES.HANDLE_AUCTION_QUOTE:
				return <QuoteSubmission {...drawerTypeMeta} />
			case DRAWER_TYPES.ADD_LEDGERS:
				return <AddTransaction label={strings('add', 'transaction')} {...drawerTypeMeta} />
			case DRAWER_TYPES.ADMIN_USER_DASHBOARD_VIEW:
				return withSuspense(<Dashboard {...drawerTypeMeta} />)
			case DRAWER_TYPES.ADD_NEW_LOGISTICS_ITEM:
				return <CreateLogisticsItem label={strings('add_item_details')} {...drawerTypeMeta} />
			case DRAWER_TYPES.EDIT_LOGISTICS_ITEM:
				return <CreateLogisticsItem label={strings('edit_item_details')} {...drawerTypeMeta} />
			case DRAWER_TYPES.ADD_NEW_LOGISTICS_QUOTES:
				return <AddLogisticsQuotes label={strings('add', 'quotes')} {...drawerTypeMeta} />
			case DRAWER_TYPES.EDIT_LOGISTICS_QUOTES:
				return <AddLogisticsQuotes label={strings('edit', 'quotes')} {...drawerTypeMeta} />
			case DRAWER_TYPES.LOGISTICS_PARTNER_ADD_QUOTES:
				return <LogisticsPartnerQuotes {...drawerTypeMeta} />
			case DRAWER_TYPES.LOGISTICS_PARTNER_EDIT_QUOTES:
				return <LogisticsPartnerQuotes {...drawerTypeMeta} />
			case DRAWER_TYPES.ADMIN_LOGISTICS_ORDER_INVOICE:
				return <AdminEditLogisticsInvoice {...drawerTypeMeta} />
			case DRAWER_TYPES.ADMIN_SHARE_ENQUIRY:
				return <ShareEnquiry {...drawerTypeMeta} />
			case DRAWER_TYPES.ADD_ORG_REMARKS:
				return <OrgRemark {...drawerTypeMeta} />
			case DRAWER_TYPES.CLOUD_ORDER_INVENTORY_ITEMS:
				return <CloudFactoryAddInventoryItems {...drawerTypeMeta} label={strings('select', 'inventory')} />
			case DRAWER_TYPES.CLOUD_ORDER_ENQUIRY_ITEMS:
				return <CloudFactoryAddEnquiryItems {...drawerTypeMeta} label={strings('select', 'items')} />
			case DRAWER_TYPES.ADMIN_EDIT_FABRICATION_INVOICE:
				return <AdminEditFactoryInvoice {...drawerTypeMeta} />
			case DRAWER_TYPES.EDIT_FABRICATION_INVOICE:
				return <EditFactoryInvoice {...drawerTypeMeta} />
			case DRAWER_TYPES.MANAGE_FACTORY_ORDER_ITEM_JOURNEY:
				return <ManageItemJourney {...drawerTypeMeta} />
			case DRAWER_TYPES.MANAGE_ORDER_WASTE_ITEM:
				return <ManageOrderWasteItem {...drawerTypeMeta} />
			case DRAWER_TYPES.CLOSING_REASON_ENQUIRY:
				return <ClosingReasonForm {...drawerTypeMeta} />
			case DRAWER_TYPES.LOGISTICS_ADD_PRICE:
				return <AddLogisticsPrice {...drawerTypeMeta} />
			case DRAWER_TYPES.ADMIN_FREIGHT_CALCULATOR:
				return <AdminFreightCalculator {...drawerTypeMeta} label={strings(['freight', 'calculator'])} />
			case DRAWER_TYPES.FREIGHT_CALCULATOR:
				return <FreightCalculator {...drawerTypeMeta} label={strings(['freight', 'calculator'])} />
			case DRAWER_TYPES.ADMIN_GENERATE_ESTIMATE:
				return <AdminGenerateEstimate {...drawerTypeMeta} />
			case DRAWER_TYPES.ADMIN_INVOICES:
				return <AdminInvoices {...drawerTypeMeta} />
			case DRAWER_TYPES.ADD_UNDERWRITING:
				return <AdminNewReport {...drawerTypeMeta} />
			case DRAWER_TYPES.UNDERWRITING_STATUS:
				return <UnderwritingStatus {...drawerTypeMeta} />
			case DRAWER_TYPES.UNDERWRITING_PAST_REPORT_STATUS:
				return <PastReport {...drawerTypeMeta} />
			case DRAWER_TYPES.SELES_TEAM_FEEDBACK:
				return <AdminSelesTeamFeedback {...drawerTypeMeta} />
			case DRAWER_TYPES.FINANCE_TEAM_FEEDBACK:
				return <AdminFinanceFeedback {...drawerTypeMeta} />
			case DRAWER_TYPES.CROSS_DIRECTOR_LIST:
				return <AdminCrossDirectorList {...drawerTypeMeta} />
			case DRAWER_TYPES.COMPLETE_YOUR_KYC:
				return <CompleteKYCDrawer {...drawerTypeMeta} label={strings(['complete', 'your', 'kyc'])} />
			case DRAWER_TYPES.ADD_COMMODITY_PRICES:
				return <AddCommodityPrice {...drawerTypeMeta} />
			case DRAWER_TYPES.REQUEST_CREDIT:
				return <RequestCredit {...drawerTypeMeta} />
			case DRAWER_TYPES.NOTIFICATIONS:
				return <NotificationsList {...drawerTypeMeta} />
			case DRAWER_TYPES.ADD_ORG_ADDRESS:
				return <AddOrgAddress {...drawerTypeMeta} />
			case DRAWER_TYPES.ADD_ORG_GST:
				return <AddGST {...drawerTypeMeta} />
			case DRAWER_TYPES.ADD_ORG_ROLE:
				return <AddOrgRole {...drawerTypeMeta} />
			case DRAWER_TYPES.ADD_USER_COMMODITY:
				return <AddCommodity {...drawerTypeMeta} />
			case DRAWER_TYPES.COMMODITY_CATEGORY_LIST:
				return <CommodityCategoryList {...drawerTypeMeta} />
			case DRAWER_TYPES.EDIT_ORGANISATION_PAN:
				return <EditOrgPanDetails {...drawerTypeMeta} />
			case DRAWER_TYPES.EDIT_ORGANISATION_NAME:
				return <EditOrgName {...drawerTypeMeta} />
			case DRAWER_TYPES.EDIT_USER_DETAILS:
				return <EditUserDetails {...drawerTypeMeta} />
			case DRAWER_TYPES.ADD_NEW_USER:
				return <AddNewUser {...drawerTypeMeta} />
			case DRAWER_TYPES.ADD_NEW_ORDER_ITEM:
				return <AddOrderItem {...drawerTypeMeta} />
			case DRAWER_TYPES.ADD_NEW_ORGANISATION:
				return <AddNewOrganisation {...drawerTypeMeta} />
			case DRAWER_TYPES.MERGE_ORGANISATION:
				return <MergeOrganisation {...drawerTypeMeta} />
			case DRAWER_TYPES.CHOOSE_PHOTO:
				return <AdminChoosePhoto {...drawerTypeMeta} />
			case DRAWER_TYPES.MANAGE_ENQUIRY_JOURNEY:
				return <EnquiryJourney {...drawerTypeMeta} />
			case DRAWER_TYPES.MANAGE_ORDER_JOURNEY:
				return <OrderJourney {...drawerTypeMeta} />
			case DRAWER_TYPES.ADD_COLLECTION:
				return <CreateCollection {...drawerTypeMeta} />
			case DRAWER_TYPES.COLLECTION_JOURNEY:
				return <CollectionJourney {...drawerTypeMeta} />
			case DRAWER_TYPES.SELECT_SUPPLIER_SCRAP:
				return <SelectScrapSupplier {...drawerTypeMeta} />
			case DRAWER_TYPES.EDIT_TASKS:
				return <EditTasks {...drawerTypeMeta} />
			case DRAWER_TYPES.ADD_LEAD:
				return <AddLead {...drawerTypeMeta} />
			case DRAWER_TYPES.LEAD_COMPLETE:
				return <CompleteLead {...drawerTypeMeta} />
			case DRAWER_TYPES.LEAD_JOURNEY:
				return <LeadJourney {...drawerTypeMeta} />
			case DRAWER_TYPES.EDIT_LEAD_MANAGER:
				return <EditLeadManager {...drawerTypeMeta} />
			case DRAWER_TYPES.ADMIN_MONTHLY_TARGET:
				return <MonthlyTarget {...drawerTypeMeta} />
			case DRAWER_TYPES.DELETE_ADMIN:
				return <DeleteAdmin {...drawerTypeMeta} />
			case DRAWER_TYPES.ADD_BANK_ACCOUNT:
				return <AddBank {...drawerTypeMeta} />
			case DRAWER_TYPES.ADD_PDC:
				return <PdcForm {...drawerTypeMeta} />
			case DRAWER_TYPES.EDIT_PDC:
				return <PdcForm {...drawerTypeMeta} />
			case DRAWER_TYPES.BUSINESS_DETAILS:
				return <BusinessDetailsForm {...drawerTypeMeta} />
			case DRAWER_TYPES.ADHOC_EDIT_INSURER_CREDIT:
				return <EditInsurerCredit {...drawerTypeMeta} />
			case DRAWER_TYPES.FINANCIAL_REMARK:
				return <Remark {...drawerTypeMeta} />
			case DRAWER_TYPES.FINANCIAL_ORG_BANK:
				return <BankForm {...drawerTypeMeta} />
			case DRAWER_TYPES.FINANCIAL_ORG_SECURITY:
				return <SecurityForm {...drawerTypeMeta} />
			case DRAWER_TYPES.FINANCIAL_ORG_PAYMENT_MODE:
				return <PaymentModeForm {...drawerTypeMeta} />
			case DRAWER_TYPES.DATA_CORRECTION_TRADING_EXPENSE:
				return <TradingExpenseForm {...drawerTypeMeta} />
			case DRAWER_TYPES.ADD_BLOG_FAQ:
				return <AddFaq {...drawerTypeMeta} />
			case DRAWER_TYPES.ADD_CREDIT_LIMIT:
				return <AddCreditLimit {...drawerTypeMeta} />
			case DRAWER_TYPES.ORG_SERVICES:
				return <OrgServices {...drawerTypeMeta} />
			case DRAWER_TYPES.ADD_FINANCIAL_DOC:
				return <AddFinancialDoc {...drawerTypeMeta} />
			case DRAWER_TYPES.ORG_INCORPORATION_DATE:
				return <EditIncorporationDate {...drawerTypeMeta} />
			default:
				return null
		}
	}

	const closeDrawerHandler = () => {
		if (drawerTypeMeta.close) {
			drawerTypeMeta.close()
		}

		dispatch(toggleDrawer(false))
	}

	const renederedComponent = useMemo(() => {
		return renderComponentFromDrawerType()
	}, [drawerType])

	useEffect(() => {
		if (showDrawer) {
			const escapeKeyPress = (e) => {
				if (e.key === 'Escape') {
					dispatch(toggleDrawer(false))
					document.removeEventListener('keydown', escapeKeyPress)
				}
			}
			document.addEventListener('keydown', escapeKeyPress)
		}
	}, [showDrawer])

	return (
		<>
			<DrawerOverlay showDrawer={showDrawer} enquiryPhotos={drawerTypeMeta?.chooseEnquiryPhoto?.image_paths}>
				{drawerTypeMeta?.chooseEnquiryPhoto?.image_paths && (
					<ImageWapper>
						<Image src={downloadUrl + drawerTypeMeta?.chooseEnquiryPhoto?.image_paths} />
					</ImageWapper>
				)}
			</DrawerOverlay>
			<DrawerWrapper showDrawer={showDrawer}>
				<DrawerHeader>
					<IconWrapper onClick={closeDrawerHandler}>
						<ArrowBack />
					</IconWrapper>
					{drawerTypeMeta?.label || renederedComponent?.props.label}
				</DrawerHeader>
				<DrawerBody>{renederedComponent}</DrawerBody>
			</DrawerWrapper>
		</>
	)
}

export default Drawer
