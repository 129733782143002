import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ExitToApp, ExpandMore, ExpandLess, Language } from '@material-ui/icons'
import styled from 'styled-components'
import { Scrollbars } from 'react-custom-scrollbars-2'

import {
	SideMenuWrapper,
	SideMenuOverlay,
	ScrollableMenuWrapper,
	MenuItem,
	MenuIcon,
	MenuLabel,
	DropdownLavel,
	LogoWrapper,
	ProfileWrapper,
	InfoWrapper,
	Avatar,
	ProfileName,
	MenuWithSubMenuWrapper,
	MenuTitle,
	ConcernedManagerWrapper,
} from './styles'

import { getRoleBasedMenu, paths } from '@routes'
import { Link, Logo, Button, Pill, TOAST_TYPE, CircularProgressBar, Select } from '@base'
import { DRAWER_TYPES, cookieKeys, roles, strings, languages as LanguagesConstants, MB_ROLES } from '@constants'
import { getPlatform, logout } from '@utils'
import { showLoader, showToast } from '@data/state/action'
import { toggleDrawer } from '@data/state/action/root'
import { Person as ProfileIcon, BusinessCenter as BusinessCenterIcon } from '@material-ui/icons'
import { updateOrganisation, updateUser } from '@data/state/action/user'
import Cookies from 'universal-cookie'
import { isClient, isBuyer } from '@utils/adminFilter'

const defaultName = 'Hello'

const getRoute = (role) => {
	if (!isClient(role)) return paths.admin_profile
	return paths.profile
}

const StyledButton = styled(Button)`
	margin: 0;
	width: 100%;
`

const SideMenu = ({ user, showMenu, menuCloseHandler }) => {
	const selectedTitle = useSelector((state) => state.getIn(['root', 'sidebarTitle']))
	const organisation = useSelector((state) => state.getIn(['user', 'organisation']))

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const usePlatform = getPlatform()
	const [userProfile, setUserProfile] = useState(true)
	const [selectedCommodity, setSelectedCommodity] = useState()
	const [selectedLanguage, setLanguage] = useState('en')

	const languages = Object.values(LanguagesConstants).map((lang) => ({ language: lang }))

	const onLogout = () => {
		logout(navigate, true)
	}

	useEffect(() => {
		const cookies = new Cookies()
		let lang = cookies.get(cookieKeys.language)
		if (!lang) {
			lang = 'en'
			cookies.set(cookieKeys.language, lang, { path: '/' })
		}
		setLanguage(lang)
	}, [])

	const renderLogo = () => {
		return (
			<LogoWrapper>
				<Logo size='2.75rem' horizontal clickHandler={logoClick} />
			</LogoWrapper>
		)
	}

	const logoClick = () => {
		menuCloseHandler(false)
		if (isClient(user?.role)) { 
			navigate('/')
		} else { 
			navigate(`/${MB_ROLES.ADMIN.toLowerCase()}`)
		}
		
	}

	const onClick = (item) => () => {
		menuCloseHandler(false)
		// close drawer
		dispatch(toggleDrawer(false))
		if (item.active === false) {
			dispatch(showToast(true, strings('msg_available_phase_2'), { type: TOAST_TYPE.INFO }))
		}
	}

	const requestCreditHandler = () => {
		dispatch(
			toggleDrawer(true, DRAWER_TYPES.REQUEST_CREDIT, {
				label: strings('request', 'credit'),
			})
		)
	}

	const renderSubMenu = (submenu) => {
		return submenu.map((item, index) => {
			let selected = selectedTitle.toLowerCase() === item.title.toLowerCase()
			if (item.href && !item.active) {
				return (
					<Link flexgrow={1} href={item.href} key={`submenu_${index}_${item.title}`} onClick={onClick(item)}>
						<MenuItem selected={selected}>
							<MenuIcon>{item?.icon}</MenuIcon>
							<MenuLabel>{item.title}</MenuLabel>
						</MenuItem>
					</Link>
				)
			}
			return (
				<MenuItem selected={selected} key={`submenu_${index}_${item.title}`} onClick={onClick(item)}>
					<MenuIcon>{item?.icon}</MenuIcon>
					<MenuLabel>{item.title}</MenuLabel>
				</MenuItem>
			)
		})
	}

	const renderMenuItem = useCallback(() => {
		const menus = getRoleBasedMenu(user?.role, organisation?.roles)
		return menus.map((item, index) => {
			let selected = selectedTitle.toLowerCase() === item.title.toLowerCase()
			if (item.submenu) {
				const selectedSubMenu = item.submenu.find((i) => i.title.toLowerCase() === selectedTitle.toLowerCase())
				if (selectedSubMenu) {
					selected = true
				}
				return (
					<MenuWithSubMenuWrapper key={`menu_${index}_${item.title}`}>
						<MenuTitle>{item?.title}</MenuTitle>
						{renderSubMenu(item.submenu)}
					</MenuWithSubMenuWrapper>
				)
			} else if (item.active === false) {
				return (
					<MenuItem key={`menu_${index}_${item.title}`} onClick={onClick(item)}>
						<MenuIcon>{item?.icon}</MenuIcon>
						<MenuLabel>{item?.title}</MenuLabel>
					</MenuItem>
				)
			}
			return (
				<Link href={item.href} key={`menu_${index}_${item.title}`} onClick={onClick(item)}>
					<MenuItem selected={selected}>
						<MenuIcon>{item?.icon}</MenuIcon>
						<MenuLabel>{item?.title}</MenuLabel>
					</MenuItem>
				</Link>
			)
		})
	}, [user, organisation, selectedTitle])

	const languageChangeHandler = (value) => {
		const cookies = new Cookies()
		cookies.set(cookieKeys.language, value.language, { path: '/' })
		setLanguage(value.language)
		window.location.reload(false)
	}

	const renderConcernedManager = () => {
		if (user?.role === roles.logistic) {
			return (
				<ConcernedManagerWrapper>
					<h6 isManager>{strings('concerned_manager')}</h6>
					<Pill
						label={
							<>
								<b>Aakash </b>(919871896723)
							</>
						}
					/>
				</ConcernedManagerWrapper>
			)
		}

		return null
	}

	const calculateCompleteProfilePercent = useMemo(() => {
		let percent = 0
		const values = {
			files: 13, // 3
			mandatory: 5, // 10
			optional: 3, // 3
			warehouse: 2,
		}
		if (user?.user_kyc_data) {
			const kycData = user?.user_kyc_data
			percent += (kycData.pan_verification_details && values.files) || 0
			percent += (kycData.aadhaar_file_response && values.files) || 0

			if (kycData.documents) {
				percent += (kycData.documents.kyc_video && values.files) || 0

				percent += (kycData.documents.pan && values.mandatory) || 0
				percent += (kycData.documents.aadhaar && values.mandatory) || 0
				percent += (kycData.documents.passport_photo && values.mandatory) || 0

				// office document
				percent += (kycData.documents.office_documents?.photos && values.mandatory) || 0
				if (kycData.documents.office_documents?.property_type === 'owned') {
					percent += (kycData.documents.office_documents?.utitlity_bill && values.mandatory * 3) || 0
				} else {
					percent += (kycData.documents.office_documents?.rent_agreement && values.mandatory) || 0
					percent += (kycData.documents.office_documents?.utitlity_bill && values.mandatory) || 0
					percent += (kycData.documents.office_documents?.landlord_noc && values.mandatory) || 0
				}

				//financials
				percent += (kycData.documents.gst_certificate && values.mandatory) || 0
				percent += (kycData.documents.gstR2A && values.mandatory) || 0
				percent += (kycData.documents.gstR3B && values.mandatory) || 0
				percent += (kycData.documents.gstR1 && values.optional) || 0
				percent += (kycData.documents.bank_statement && values.optional) || 0
				percent += (kycData.documents.cancelled_cheque && values.optional) || 0

				percent += (kycData.documents.warehouse_documents && values.warehouse) || 0
			}
		}
		return percent
	}, [user])

	const commodityhandler = (val) => {
		setSelectedCommodity(val)
		dispatch(showLoader(true))
		const successHandler = () => {
			dispatch(showToast(true, 'Updated user successfully', { type: TOAST_TYPE.SUCCESS }))
			dispatch(toggleDrawer(false))
			navigate(organisation ? paths.dashboard : paths.admin_dashboard)
		}
		const errorHandler = (err) => {
			console.log(err)
			dispatch(showToast(true, 'Error while updating user', { type: TOAST_TYPE.ERROR }))
		}

		const finalHandler = () => {
			dispatch(showLoader(false))
		}

		if (organisation) {
			dispatch(updateOrganisation(organisation?.organisation_id, val?.commodity_id, false, successHandler, errorHandler, finalHandler))
		} else {
			const commodity = (user?.commodity || []).map((item) => ({
				...item,
				active: item?.commodity_id === val?.commodity_id,
			}))
			const updatedUserData = { ...user.toJS(), commodity }
			dispatch(updateUser(updatedUserData, false, successHandler, errorHandler, finalHandler))
		}
	}

	const renderProfileMenu = () => {
		return (
			<ProfileWrapper>
				<DropdownLavel
					onClick={(e) => {
						setUserProfile(!userProfile)
					}}
				>
					<InfoWrapper>
						<Avatar>{user?.full_name ? user?.full_name[0] : 'T'}</Avatar>
						<ProfileName>{user?.full_name ?? defaultName}</ProfileName>
						<MenuIcon>{userProfile ? <ExpandLess /> : <ExpandMore />}</MenuIcon>
					</InfoWrapper>
				</DropdownLavel>

				{!userProfile && (
					<>
						<Link flexgrow={1} href={getRoute(user?.role)} onClick={onClick({ title: 'User Profile' })}>
							<MenuItem selected={selectedTitle === strings('my_profile')}>
								<MenuIcon>
									{isClient(user?.role) ? (
										<CircularProgressBar percentage={calculateCompleteProfilePercent} />
									) : (
										<ProfileIcon />
									)}
								</MenuIcon>
								<MenuLabel>{strings('my_profile')}</MenuLabel>
							</MenuItem>
						</Link>
						<MenuItem>
							<MenuIcon>
								<BusinessCenterIcon />
							</MenuIcon>
							<MenuLabel>
								<Select
									label={strings('select', 'commodity')}
									small
									data={organisation ? organisation?.commodity : user?.commodity || []}
									displayKey='commodity_name'
									primaryKey='commodity_name'
									openBelow={false}
									value={
										(organisation ? organisation?.commodity : user?.commodity || []).find(
											(item) => item?.commodity_name === selectedCommodity?.commodity_name || item.active
										) || {}
									}
									onChange={(value) => commodityhandler(value)}
								/>
							</MenuLabel>
						</MenuItem>
						<MenuItem>
							<MenuIcon>
								<Language />
							</MenuIcon>
							<MenuLabel>
								<Select
									label={strings('language')}
									small
									data={languages}
									displayKey='language'
									primaryKey='language'
									value={{ language: selectedLanguage }}
									onChange={languageChangeHandler}
									openBelow={false}
								/>
							</MenuLabel>
						</MenuItem>
						<MenuItem onClick={onLogout}>
							<MenuIcon>
								<ExitToApp />
							</MenuIcon>
							<MenuLabel>{strings('logout')}</MenuLabel>
						</MenuItem>
					</>
				)}
			</ProfileWrapper>
		)
	}

	const menuItemWithScrolls = () => {
		if (usePlatform !== 'Mac' || usePlatform !== 'iOS') {
			return (
				<Scrollbars
					renderView={(props) => (
						<div
							{...props}
							style={{ ...props.style, overflowX: 'hidden', scrollbarColor: 'transparent transparent', marginBottom: '0' }}
						/>
					)}
				>
					{renderMenuItem()}
				</Scrollbars>
			)
		} else {
			return renderMenuItem()
		}
	}

	return (
		<>
			<SideMenuOverlay showMenu={showMenu} onClick={() => menuCloseHandler(false)} />
			<SideMenuWrapper showMenu={showMenu}>
				{renderLogo()}
				<ScrollableMenuWrapper>{menuItemWithScrolls()}</ScrollableMenuWrapper>
				{renderConcernedManager()}
				{renderProfileMenu()}
			</SideMenuWrapper>
		</>
	)
}

export default SideMenu
