import styled, { css } from 'styled-components'
import { Button, H5, H6 } from '@base'
import { Spacings, Text, Devices } from '@styles'

export const SideMenuWrapper = styled.section`
	min-width: 18rem;
	width: 18rem;
	display: flex;
	flex-direction: column;
	height: 100%;
	background: ${({ theme }) => theme.palette.background.white};
	z-index: 1000;

	position: fixed;
	top: 0;
	left: ${({ showMenu }) => (showMenu ? 0 : '-100%')};
	transition: left 0.2s cubic-bezier(0, 0, 0.2, 1);
	transition-delay: 0.05s;

	padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_4B} ${Spacings.SPACING_0B};

	@media ${Devices.laptop} {
		position: static;
		min-width: 16rem;
		width: 16rem;
		box-shadow: 0px 0.25rem 1.25rem 0 rgb(0 0 0 / 5%);
	}
	@media ${Devices.desktop} {
		position: static;
		min-width: 18.5rem;
		width: 18.5rem;
	}
`

export const SideMenuOverlay = styled.div`
	position: fixed;
	top: 0;
	left: ${({ showMenu }) => (showMenu ? 0 : '-100%')};
	width: 100vw;
	height: 100vh;
	transition: left 0.1s cubic-bezier(0, 0, 0.2, 1);
	background: ${({ theme }) => theme.palette.background.light};
	z-index: 1000;

	@media ${Devices.laptop} {
		display: none;
	}
`

export const ScrollableMenuWrapper = styled.div`
	overflow: scroll;
	display: flex;
	flex-direction: column;
	height: 100%;

	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	& {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
`

export const MenuItem = styled.div`
	display: flex;
	color: ${({ theme }) => theme.palette.text.black};
	align-items: center;
	padding: 0 ${Spacings.SPACING_3B};
	user-select: none;
	flex-wrap: nowrap;
	margin: 0.125rem 0;
	border-radius: ${Spacings.SPACING_2B};
	transition: all 0.15s ease-in-out;
	min-height: ${({ isManager }) => (isManager ? 0 : `2.75rem`)};

	${({ selected }) => {
		if (selected) {
			return css`
				color: ${({ theme }) => theme.palette.text.blue};
				background: ${({ theme }) => theme.palette.background.blueLightest};
			`
		}
	}}

	${({ isManager }) => {
		if (!isManager) {
			return css`
				cursor: pointer;
				&:hover {
					color: ${({ theme }) => theme.palette.text.blue};
					background: ${({ theme }) => theme.palette.background.blueLightest};
				}
			`
		}
	}}
`
export const DropdownLavel = styled.div`
	cursor: pointer;
	transition: all 0.15s ease-in-out;
	border-radius: ${Spacings.SPACING_2B};
	&:hover {
		color: ${({ theme }) => theme.palette.text.blue};
		background: ${({ theme }) => theme.palette.background.blueLightest};
	}
`

export const MenuLabel = styled(H5)`
	flex-grow: 1;
`
export const MenuIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: ${Spacings.SPACING_3B};
	& > svg {
		font-size: ${Text.X_LARGE};
	}
`

export const LogoWrapper = styled.div`
	display: flex;
	padding: ${Spacings.SPACING_4B} ${Spacings.SPACING_3B} ${Spacings.SPACING_5B};
	align-items: flex-end;
`
export const LogoImage = styled.img`
	margin-right: ${Spacings.SPACING_2B};
`
export const LogoText = styled(H5)`
	text-transform: uppercase;
	color: ${({ theme }) => theme.palette.text.white};
`
export const ProfileWrapper = styled.div`
	margin: auto 0 ${Spacings.SPACING_2B};
	padding-top: ${Spacings.SPACING_2B};
	border-top: 1px solid ${({ theme }) => theme.palette.background.searchgrey};
`
export const ProfileWrapperSupplier = styled.div`
	margin: auto 0 ${Spacings.SPACING_2B};
	padding-top: ${Spacings.SPACING_2B};
`

export const InfoWrapper = styled(MenuItem)`
	pointer-events: none;
	margin-bottom: ${Spacings.SPACING_2B};
`
export const Avatar = styled(MenuIcon)`
	min-width: 1.5rem;
	min-height: 1.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({ theme }) => theme.palette.background.messageRed};
	color: ${({ theme }) => theme.palette.text.white};
	border-radius: 50%;
	font-size: ${Text.MEDIUM};
	font-weight: ${Text.BOLD_600};
`
export const ProfileName = styled(MenuLabel)`
	color: ${({ theme }) => theme.palette.text.greydark};
	font-weight: ${Text.BOLD_600};
`
export const StyledButton = styled(Button)`
	margin: -${Spacings.SPACING_1B} 0 -${Spacings.SPACING_1B} ${Spacings.SPACING_3B};
`

export const MenuWithSubMenuWrapper = styled.div`
	padding: ${Spacings.SPACING_2B} 0 ${Spacings.SPACING_2B};
	margin: ${Spacings.SPACING_2B} 0 ${Spacings.SPACING_3B};
	border-bottom: 1px solid ${({ theme }) => theme.palette.background.searchgrey};
`
export const MenuTitle = styled(H6)`
	padding-left: ${Spacings.SPACING_3B};
	margin-bottom: ${Spacings.SPACING_3B};
	color: ${({ theme, selected }) => (selected ? theme.palette.text.blue : theme.palette.text.greydark)};
	user-select: none;
	${({ isManager }) => {
		if (!isManager) {
			return css`
				text-transform: uppercase;
			`
		}
	}}
`

export const ConcernedManagerWrapper = styled.div`
	margin: auto 0 ${Spacings.SPACING_2B};
	padding-top: ${Spacings.SPACING_2B};
	display: flex;
	flex-direction: column;

	& > h6 {
		color: ${({ theme, selected }) => (selected ? theme.palette.text.blue : theme.palette.text.greydark)};
		font-size: ${Text.SMALL};
		padding-bottom: ${Spacings.SPACING_2B};
	}

	& > span {
		width: 100%;
		font-size: ${Text.MEDIUM};
	}
`
