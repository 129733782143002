import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Devices, Spacings, Text } from '@styles'
import { displayAmount } from '@utils'
import { ArrowUpward as ArrowUpIcon, ArrowDownward as ArrowDownIcon } from '@material-ui/icons'
import { getLatestCommodityPrices } from '@data'
import { isBuyer, isClient } from '@utils/adminFilter'

const DivWrapper = styled.div`
	width: 7.25rem;
	min-height: 1.875rem;
	background: ${({ theme }) => theme.palette.background.pastelBeige};
	border-radius: 5px;
	padding: ${Spacings.SPACING_1B} ${Spacings.SPACING_2B};
	margin-right: ${Spacings.SPACING_2B};
	display: flex;
	cursor: wait;
	overflow: hidden;

	@media ${Devices.tablet} {
		width: 15rem;
	}
`

const BaseTextWrapper = styled.div`
	display: flex;
	align-items: center;
	font-size: ${Text.SMALL};
`
const TextWrapper = styled.div`
	flex: ${({ flex }) => flex || 'unset'};
	display: flex;
	flex-direction: column;
	overflow: hidden;
`

const PriceWrapper = styled(BaseTextWrapper)`
	font-weight: ${Text.BOLD_500};
	flex-direction: column;
	justify-content: flex-end;

	@media ${Devices.tablet} {
		flex-direction: row;
	}
`

const ChangeWrapper = styled(BaseTextWrapper)`
	font-size: ${Text.EXTRA_SMALL};
	color: ${({ change, theme }) => (change ? theme.palette.text.green : theme.palette.text.red)};
	letter-spacing: -0.5px;
`

const TypeWrapper = styled(ChangeWrapper)`
	margin-left: ${Spacings.SPACING_1};
	color: ${({ theme }) => theme.palette.background.borderColor};
	letter-spacing: 0px;
	white-space: nowrap;
`
const LocationWrapper = styled(ChangeWrapper)`
	margin-left: ${Spacings.SPACING_1};
	color: ${({ theme }) => theme.palette.text.black};
	letter-spacing: 0px;
	font-weight: ${Text.BOLD_500};
	justify-content:  ${({ jc }) => jc || 'unset'};
`

const IconStyle = {
	fontSize: Text.EXTRA_SMALL,
}

const PriceWidget = ({ user, organisation }) => {
	const [prices, setPrices] = useState([])
	const [current, setCurrentIndex] = useState(0)
	const [changeInPrice, setChange] = useState()
	const [timeout, setTimeoutState] = useState()
	let currentPrice = prices[current]

	const showAllPrices = () => {
		//Open floating div to show all prices
	}

	useEffect(() => {
		if (!user) return
		getLatestCommodityPrices(0, { source: 'STEELMINT' }).then((res) => changePrice(res.data))
	}, [user])

	const changePrice = (data) => {
		if (!user || !isClient(user.role)) {
			setPrices(data)
			return
		}
		
		let newPrices = Object.assign([], data)
		const multiplier = isBuyer(organisation?.roles) ? 100 : -100

		newPrices = newPrices.map((item) => {
			item.price_per_ton += multiplier * Math.ceil(1 + 4 * Math.random())
			// item.prices
			return item
		})

		setPrices(newPrices)
	}

	useEffect(() => {
		clearTimeout(timeout)
		// setTimeoutState(
		// 	setTimeout(() => {
		// 		setCurrentIndex((current + 1) % prices.length)
		// 	}, 3000)
		// )
	}, [prices, current])

	useEffect(() => {
		if (prices.length !== 0 && currentPrice.prices) {
			const length = currentPrice.prices.length
			const change = ((currentPrice.prices[length - 2] - currentPrice.prices[length - 1]) * 100) / currentPrice.prices[length - 2]
			if (change) {
				setChange(Math.floor(change))
			} else {
				setChange()
			}
		}
	}, [currentPrice])

	if (prices.length === 0) return null
	if (!currentPrice) return null

	return (
		<DivWrapper
			onMouseEnter={() => clearTimeout(timeout)}
			onMouseLeave={() => setCurrentIndex((current + 1) % prices.length)}
			onClick={showAllPrices}
		>
			<TextWrapper>
				<BaseTextWrapper>
					{currentPrice.product_name}
					{currentPrice.primary_or_secondary && currentPrice.primary_or_secondary.length > 0 && (
						<TypeWrapper>({currentPrice.primary_or_secondary.substring(0, 1).toUpperCase()})</TypeWrapper>
					)}
				</BaseTextWrapper>
				<LocationWrapper>{currentPrice.location}</LocationWrapper>
			</TextWrapper>
			<TextWrapper flex={1}>
				<PriceWrapper>
					{displayAmount(currentPrice.price_per_ton / 1000)}
					{changeInPrice && (
						<ChangeWrapper change={changeInPrice >= 0}>
							({changeInPrice >= 0 ? <ArrowUpIcon style={IconStyle} /> : <ArrowDownIcon style={IconStyle} />}
							{Math.abs(changeInPrice)}% )
						</ChangeWrapper>
					)}
				</PriceWrapper>
				{currentPrice.grade && (
					<LocationWrapper jc='flex-end'>
						<TypeWrapper>({currentPrice.grade})</TypeWrapper>
					</LocationWrapper>
				)}
			</TextWrapper>
		</DivWrapper>
	)
}

export default PriceWidget
