import styled, { css } from 'styled-components'
import { Devices, Spacings, Text } from '@styles'

const BasicFixedBox = styled.section`
	position: fixed;
	transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
	z-index: 501;
`

export const DrawerOverlay = styled(BasicFixedBox)`
	background: ${({ theme }) => theme.palette.background.light};
	opacity: ${({ showDrawer }) => (showDrawer ? '0.4' : '0')};
	visibility: ${({ showDrawer }) => (showDrawer ? 'visible' : 'hidden')};
	width: 100vw;
	height: 100vh;
	${({ enquiryPhotos }) => {
		if (enquiryPhotos) {
			return css`
				display: flex;
				flex-direction: row;
				opacity: ${({ showDrawer }) => (showDrawer ? '0.4' : '0')};
				z-index: 501;
				@media ${Devices.mobile} {
					opacity: 1;
					z-index: 1000;
				}
			`
		}
	}}
`
export const DrawerWrapper = styled(BasicFixedBox)`
	width: 100vw;
	max-width: 100vw;
	max-height: 100%;
	height: fit-content;
	min-height: 100%;
	background: ${({ theme }) => theme.palette.background.white};
	box-shadow: 0 4px 20px 0 rgb(0 0 0 / 5%);
	display: flex;
	flex-direction: column;

	right: 0;
	bottom: ${({ showDrawer }) => (showDrawer ? '0' : '-100%')};
	opacity: ${({ showDrawer }) => (showDrawer ? '1' : '0')};

	@media ${Devices.tablet} {
		top: 0;
		right: ${({ showDrawer }) => (showDrawer ? '0' : '-100%')};
		height: 100%;
		width: 520px;
		max-width: 520px;
		height: 100vh;
		max-height: 100vh;
		z-index: 1001;
	}
`

export const DrawerHeader = styled.div`
	min-height: 48px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 12px;
	box-shadow: 0 4px 20px 0 rgb(0 0 0 / 5%);
`

export const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	user-select: none;
	margin-right: 12px;

	& > svg {
		font-size: ${Text.X_LARGE};
		color: ${({ theme }) => theme.palette.text.black};
	}
`

export const DrawerBody = styled.div`
	flex-grow: 1;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;

	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	& {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
`
export const ImageWapper = styled.div`
	margin: ${Spacings.SPACING_6};
	flex-grow: 1;
	width: 100vw;
	height: 100vh;
	overflow-y: scroll;
	overflow-x: scroll;
	@media ${Devices.tablet} {
	}
	@media ${Devices.desktop} {
	}
`
export const Image = styled.img`
	width: 40vw;
	height: 100vh;
	object-fit: fill;
	@media ${Devices.tablet} {
		width: 30vw;
	}
	@media ${Devices.laptop} {
		width: 47vw;
	}
	@media ${Devices.desktop} {
		width: 60vw;
	}
`
