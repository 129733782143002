import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { MODAL_TYPES } from '@constants'
import { toggleModal } from '@data/state/action/root'
import { Devices, Spacings } from '@styles'
import { ConfirmBox } from '@base'

const BasicFixedBox = styled.section`
	position: fixed;
	transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
	z-index: 9998;
`

const ModalOverlay = styled(BasicFixedBox)`
	background: ${({ theme }) => theme.palette.background.dark};
	opacity: ${({ showModal }) => (showModal ? '0.4' : '0')};
	visibility: ${({ showModal }) => (showModal ? 'visible' : 'hidden')};
	width: 100vw;
	height: 100vh;
`
const ModalWrapper = styled(BasicFixedBox)`
	width: 100vw;
	max-width: 100vw;
	max-height: 100%;
	height: fit-content;
	background: ${({ theme }) => theme.palette.background.white};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	display: flex;
	flex-direction: column;
	overflow: hidden;

	border-top-left-radius: ${Spacings.SPACING_2B};
	border-top-right-radius: ${Spacings.SPACING_2B};

	right: 0;
	bottom: ${({ showModal }) => (showModal ? '0' : '-100%')};
	opacity: ${({ showModal }) => (showModal ? '1' : '0')};

	@media ${Devices.tablet} {
		border-radius: ${Spacings.SPACING_2B};
		bottom: ${({ showModal }) => (showModal ? '2rem' : '-100%')};
		right: 50%;
		transform: translateX(50%);
		width: fit-content;
		min-width: 30rem;
	}
`

const Modal = () => {
	const dispatch = useDispatch()
	const showModal = useSelector((state) => state.getIn(['root', 'showModal']))
	const modalType = useSelector((state) => state.getIn(['root', 'modalType']))
	const modalMeta = useSelector((state) => state.getIn(['root', 'modalMeta']))

	const onClose = (e) => {
		e.stopPropagation()
		if (modalMeta?.overlayClickable) {
			dispatch(toggleModal(false))
		}
	}

	const renderContent = () => {
		switch (modalType) {
			case MODAL_TYPES.CONFIRMATION: {
				return <ConfirmBox {...modalMeta} />
			}
			default:
				return null
		}
	}

	return (
		<>
			<ModalOverlay showModal={showModal} onClick={onClose} />
			<ModalWrapper showModal={showModal}>{renderContent()}</ModalWrapper>
		</>
	)
}

export default Modal
